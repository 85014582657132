import { Dictionary } from "@avesdo-common/src/lib/models/generic/Dictionary";
import { AvesdoCompartors } from "@avesdo-common/src/lib/comparators/avesdo-comparators";
import { GridProperty } from "@avesdo-common/src/lib/models/building-models/GridProperty";
import { PropertyDetail } from "./PropertyDetail";
import { CheckboxState } from '@avesdo-common/src/lib/enums/CheckboxState';
import { uniq } from "lodash";

export interface FloorPlan {
  id: number;
  label: string;
  propertyIds: number[];
  unitNumbers: string[];
  floorSections: string[];
  checkboxState?: CheckboxState,
  count?: number;
  bedrooms: number;
  bathrooms: number;
  den: number;
  floorPlanImage: string;
  floorArea: number;
  floorPlanFromPrice: number;
  outdoorArea: number;
};

export const getPropertiesByFloorPlan = (propertiesList: (GridProperty | PropertyDetail)[]): FloorPlan[] => {
    const floorsPlanDict = propertiesList
      .filter((property) => property)
      .reduce((dict: Dictionary<FloorPlan>, property) => {
        const {
          id,
          floorPlan,
          floorArea,
          floorPlanImage,
          bedrooms,
          bathrooms,
          den,
          floorPlanFromPrice,
          outdoorArea
        } = property;

        dict[floorPlan] = dict[floorPlan] || {
          id,
          label: floorPlan,
          propertyIds: [],
          unitNumbers: [],
          floorSections: [],
          bedrooms,
          bathrooms,
          den,
          floorPlanImage,
          floorArea,
          floorPlanFromPrice,
          outdoorArea
        };

        dict[floorPlan] = {
          ...dict[floorPlan],
          propertyIds: dict[floorPlan].propertyIds.concat([property.id]),
          unitNumbers: dict[floorPlan].unitNumbers.concat([property.unitNumber]),
          floorSections: uniq(dict[floorPlan].floorSections.concat([property.floorSection]))
        }

      return dict;
    }, {});

    return Object.keys(floorsPlanDict).map((key) => floorsPlanDict[key])
      .sort((a, b) => AvesdoCompartors.ascendingSort(a.label, b.label));
}