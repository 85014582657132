import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'bedroomsLabel'
})
export class BedroomsLabelPipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(value: {bedrooms: number, den?: number}): string {
    if (!value.bedrooms && !value.den) {
      return this.translate.instant('GENERIC.STUDIO');
    } else if (value.den) {
      return  this.translate.instant('HOME.NUM_BED_AND_DEN', {bed: value.bedrooms, den: value.den});
    } else {
      return this.translate.instant('HOME.NUM_BED', {bed: value.bedrooms});
    }
  }

}
