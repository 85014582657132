import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BuyerChoiceCard } from '@avesdo-common/src/lib/feature/selection/models/BuyerChoiceCard';
import { FormGroup } from '@angular/forms';
import { FloorRange } from '@avesdo-common/src/lib/feature/selection/models/FloorRange';

@Component({
  selector: 'app-buyer-choices',
  templateUrl: './buyer-choices.component.html',
  styleUrls: ['./buyer-choices.component.scss']
})
export class BuyerChoicesComponent {
  @Input() readonly?: boolean;
  @Input() maxNumberOfChoices: number;
  @Input() isFloorPlanBased: boolean;
  @Input() choices: BuyerChoiceCard[];
  @Input() floorRanges: FloorRange[];
  @Input() disableChoiceEdit?: boolean;

  @Output() openUnitPicker = new EventEmitter<{ index: number }>();
  @Output() removeChoice = new EventEmitter<BuyerChoiceCard>();
  @Output() increasePriority = new EventEmitter<FormGroup>();
  @Output() decreasePriority = new EventEmitter<FormGroup>();

  get numberOfChoicesRemaining() {
    return `${this.maxNumberOfChoices - this.choices.length}/${this.maxNumberOfChoices}`;
  }

  getFloorRangeByChoice(choice: BuyerChoiceCard): FloorRange {
    return this.floorRanges.find(fr => fr.floorSections.includes(choice.property?.floorSection));
  }
}
