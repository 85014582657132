import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import Glide from '@glidejs/glide';
import { ImagePreviewService } from '../../services/images/image-preview.service';

@Component({
  selector: 'lib-av-img-gallery',
  templateUrl: './av-img-gallery.component.html',
  styleUrls: ['./av-img-gallery.component.scss']
})
export class AvImgGalleryComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() imageUrls: string[];
  @Input() interval?: number;
  @Input() options?: {[option: string]: string};// glide js options
  @Output() changeIndex: EventEmitter<number> = new EventEmitter<number>(); 
  glideInstance: any;
  imgUrlsNoImage: boolean[];

  constructor(private imagePreviewServ: ImagePreviewService) { }

  expandImage(imageUrl: string, index: number) {
    if (this.imgUrlsNoImage[index])
      return;
    this.imagePreviewServ.openBasicImage(imageUrl, '');
  }

  handleNoImage(index: number) {
    this.imgUrlsNoImage[index] = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.interval) {
      this.glideInstance?.update({
        autoplay: changes.interval.currentValue
      });
    }
    if (changes.imageUrls.currentValue) {
      this.imgUrlsNoImage = new Array(changes.imageUrls.currentValue.length).fill(
        false
      );
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.glideInstance = new Glide('#av-img-gallery-carousel', {
      type: 'carousel',
      autoplay: this.interval,
      startAt: 0,
      ...this.options
    }).mount();

    this.glideInstance.on('run', () => this.changeIndex.emit(this.glideInstance.index));
  }
}
