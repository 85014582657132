import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'lib-av-table-select',
  templateUrl: './av-table-select.component.html',
  styleUrls: ['./av-table-select.component.scss']
})
export class AvTableSelectComponent implements OnInit {
  @Input() label?: string;
  @Input() hide?: boolean;
  @Input() disabled?: boolean;
  @Input() hideText?: string;
  @Input() formControl: FormControl;
  @Input() options: {value: any, label: string}[];
  @Input() tooltip?: string;
  @Output() change = new EventEmitter<any>();

  constructor() { }

  onChange() {
    this.change.emit({formControl: this.formControl});
  }

  ngOnInit(): void {
  }

}
