import {
  Component,
  Inject,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// DON'T REMOVE DYNAMIC COMMENT
// @dynamic
@Component({
  selector: 'lib-basic-image',
  templateUrl: './basic-image.component.html',
  styleUrls: ['./basic-image.component.scss'],
})
export class BasicImageComponent {
  @ViewChild('image') image: ElementRef;
  @ViewChild('container') container: ElementRef;

  private imageX: number;
  private imageY: number;
  private mouseDownX: number;
  private mouseDownY: number;
  private dragging = false;
  private startPosition = {
    top: '0px',
    left: '0px',
  };

  imgPositionStyle = this.startPosition;
  zoomScale = 1;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { imageSrc: string; imageAltName: string }
  ) {}

  @HostListener('document:mousedown', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  onDown(e) {
    if (this.dragging) return;

    const { eventX, eventY } = this.parseEvent(e);

    const {
      offsetLeft: containerLeft,
      offsetTop: containerTop,
      offsetHeight: containerHeight,
      offsetWidth: containerWidth,
    } = this.container.nativeElement;

    const { nativeElement: imageEl } = this.image;

    this.mouseDownX = eventX;
    this.mouseDownY = eventY;
    this.imageX = imageEl.offsetLeft - containerLeft - (containerWidth - imageEl.offsetWidth) / 2;
    this.imageY = imageEl.offsetTop - containerTop - (containerHeight - imageEl.offsetHeight) / 2;
    this.dragging = true;
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:touchmove', ['$event'])
  onMove(e) {
    if (this.dragging) {
      const { eventX, eventY } = this.parseEvent(e);

      this.imgPositionStyle = {
        left: `${eventX - this.mouseDownX + this.imageX}px`,
        top: `${eventY - this.mouseDownY + this.imageY}px`,
      };
    }
  }

  @HostListener('document:mouseup', ['$event'])
  @HostListener('document:touchend', ['$event'])
  onUp(e) {
    this.dragging = false;
  }

  zoomIn() {
    this.zoomScale++;
  }

  zoomOut() {
    if (this.zoomScale > 1) this.zoomScale--;
    if (this.zoomScale === 1) this.imgPositionStyle = this.startPosition;
  }

  private parseEvent(e): { eventX: number; eventY: number } {
    if (e.type === 'touchmove' || 'touchstart') {
      const touch =
        (e.touches && e.touches[0]) ||
        (e.changedTouches && e.changedTouches[0]);

      if (touch)
        return {
          eventX: touch.pageX,
          eventY: touch.pageY,
        };
    }

    return {
      eventX: e.clientX,
      eventY: e.clientY,
    };
  }
}
