import { NgModule, APP_INITIALIZER, ModuleWithProviders } from '@angular/core';
import { MaterialModule } from './material.module';
import { AgMatCheckboxComponent } from './components/ag-grid/ag-mat-checkbox/ag-mat-checkbox.component';
import { AgImgCellPopupComponent } from './components/ag-grid/ag-img-cell-popup/ag-img-cell-popup.component';
import { BasicImageComponent } from './components/basic-image/basic-image.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  TranslateModule,
  TranslateService,
  TranslateLoader,
} from '@ngx-translate/core';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageService } from './services/language/language.service';
import { AgGridModule } from 'ag-grid-angular';
import { HeaderComponent } from './components/header/header.component';
import { AuthorizedComponent } from './components/authorized/authorized.component';
import { RouterModule } from '@angular/router';
import { MatTooltipOnEllipsisDirective } from './directives/mat-tooltip-on-ellipsis.directive';
import { NoCacheService } from './services/network/nocache.service';
import { LabelValueComponent } from './components/label-value/label-value.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { PropertyGalleryInfoComponent } from './components/property/property-gallery-info.component';
import { ProjectCardComponent } from './components/project-card/project-card.component';
import { AvRouterLinkComponent } from './components/av-router-link/av-router-link.component';
import { ImagePreviewLinkComponent } from './components/image-preview-link/image-preview-link.component';
import { BedBathLabelComponent } from './components/bed-bath-label/bed-bath-label.component';
import { WizardLoaderDirective } from './directives/wizard-loader.directive';
import { TimeRemainingPipe } from './pipes/time-remaining.pipe';
import { UnitPriceFormatPipe } from './pipes/unit-price-format.pipe';
import { SortByPipe } from './pipes/sortby.pipe';
import { InfiniteScrollContainerComponent } from './components/infinite-scroll-container/infinite-scroll-container.component';
import { PropertyCardComponent } from './components/property-card/property-card.component';
import {CustomDatePipe} from '@avesdo-common/src/lib/pipes/custom-date.pipe';
import { ThousandsFormatPipe } from './pipes/thousands-format.pipe';
import { SelectionModule } from '@avesdo-common/src/lib/feature/selection/selection.module';
import { AvTableModule } from './feature/av-table/av-table.module';
import {CommonModule} from '@angular/common';
import { AsyncButtonComponent } from './components/async-button/async-button.component';
//import { ConfirmationDialogComponent } from '@avesdo-common/src/lib/components/confirmation-dialog/confirmation-dialog.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { CustomFormsModule } from './feature/custom-forms/custom-forms.module';
import { CopyClipboardDirective } from './directives/av-copy-clipboard.directive';
import { avesdoCommonReducerToken, getAvesdoCommonReducers } from './redux/avesdo-common.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PublicItemsEffects } from '@avesdo-common/src/lib/feature/custom-forms/redux/PublicItems/public-items.effects';
import { GalleryDialogComponent } from './components/gallery-dialog/gallery-dialog.component';
import { AsyncButtonStrokedComponent } from './components/async-button/async-button-stroked.component';
import { SharedModule } from './shared.module';
import { DateTimeInputComponent } from './components/date-time-input/date-time-input.component';
import { StatusColorComponent } from './components/status-color/status-color.component';
import { AvTableIconComponent } from './feature/av-table/components/av-table-icon/av-table-icon.component';
import { MatSelectAllOptionComponent } from './components/mat-select-all-option/mat-select-all-option.component';
import { AvButtonGroupComponent } from './components/av-button-group/av-button-group.component';
import { AvChartComponent } from './components/av-chart/av-chart.component';
import { AvCheckPermissionDirective } from './directives/av-check-premission.directive';
import { AvTabButtonComponent } from './components/av-tab-button/av-tab-button.component';
import { AvBackdropComponent } from './components/av-backdrop/av-backdrop.component';
import { UnitSlNamePipe } from './pipes/unit-sl-name.pipe';
import { UnitHeaderNamePipe } from './pipes/unit-header-name.pipe';
import { PropertySignersPipe } from './pipes/property-signers.pipe';
import { UserLabelRolePipe } from './pipes/user-label-role.pipe';
import { PropUnitSlNamePipe } from './pipes/prop-unit-sl-name.pipe';
import { AvImgGalleryComponent } from './components/av-img-gallery/av-img-gallery.component';
//export Required for Aot
export function HttpFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/locale/', '.json');
}

export function setupTranslateFactory(service: TranslateService) {
  return () => service.use(localStorage.getItem('lang') || 'en');
}

// @dynamic
@NgModule({
  declarations: [
    AgMatCheckboxComponent,
    AgImgCellPopupComponent,
    BasicImageComponent,
    //ConfirmationDialogComponent,
    DateInputComponent,
    HeaderComponent,
    AuthorizedComponent,
    MatTooltipOnEllipsisDirective,
    LabelValueComponent,
    GalleryComponent,
    ProjectCardComponent,
    PropertyCardComponent,
    PropertyGalleryInfoComponent,
    AvRouterLinkComponent,
    ImagePreviewLinkComponent,
    WizardLoaderDirective,
    TimeRemainingPipe,
    UnitPriceFormatPipe,
    SortByPipe,
    InfiniteScrollContainerComponent,
    CustomDatePipe,
    ThousandsFormatPipe,
    BedBathLabelComponent,
    AsyncButtonComponent,
    AsyncButtonStrokedComponent,
    CopyClipboardDirective,
    GalleryDialogComponent,
    DateTimeInputComponent,
    StatusColorComponent,
    AvTableIconComponent,
    MatSelectAllOptionComponent,
    AvButtonGroupComponent,
    AvTabButtonComponent,
    AvBackdropComponent,
    AvImgGalleryComponent,
    AvChartComponent,
    AvCheckPermissionDirective,
    UnitSlNamePipe,
    UnitHeaderNamePipe,
    PropertySignersPipe,
    UserLabelRolePipe,
    PropUnitSlNamePipe
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    CommonModule,
    SharedModule,
    AgGridModule.withComponents([
      AgMatCheckboxComponent,
      AgImgCellPopupComponent,
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpFactory,
        deps: [HttpClient],
      },
    }),
    SelectionModule,
    AvTableModule,
    CustomFormsModule,
    StoreModule.forFeature('avesdoCommon', avesdoCommonReducerToken),
    EffectsModule.forFeature([
      PublicItemsEffects
    ]),
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    AgGridModule,
    //ConfirmationDialogComponent,
    DateInputComponent,
    DateTimeInputComponent,
    SelectionModule,
    AgMatCheckboxComponent,
    AgImgCellPopupComponent,
    BasicImageComponent,
    LabelValueComponent,
    HeaderComponent,
    TranslateModule,
    AuthorizedComponent,
    RouterModule,
    MatTooltipOnEllipsisDirective,
    GalleryComponent,
    ProjectCardComponent,
    PropertyCardComponent,
    PropertyGalleryInfoComponent,
    AvRouterLinkComponent,
    ImagePreviewLinkComponent,
    BedBathLabelComponent,
    AsyncButtonComponent,
    AsyncButtonStrokedComponent,
    WizardLoaderDirective,
    TimeRemainingPipe,
    UnitPriceFormatPipe,
    SortByPipe,
    InfiniteScrollContainerComponent,
    CustomDatePipe,
    ThousandsFormatPipe,
    SelectionModule,
    AvTableModule,
    CustomFormsModule,
    CopyClipboardDirective,
    GalleryDialogComponent,
    SharedModule,
    StatusColorComponent,
    MatSelectAllOptionComponent,
    AvButtonGroupComponent,
    AvCheckPermissionDirective,
    AvChartComponent,
    AvTabButtonComponent,
    AvBackdropComponent,
    AvImgGalleryComponent,
    UnitSlNamePipe,
    UnitHeaderNamePipe,
    PropertySignersPipe,
    UserLabelRolePipe,
    PropUnitSlNamePipe
  ],
  entryComponents: [
    //ConfirmationDialogComponent,
    BasicImageComponent,
    AvRouterLinkComponent,
    ImagePreviewLinkComponent,
    BedBathLabelComponent
  ],
})
export class AvesdoCommonModule {
  public static forRoot(environment: any): ModuleWithProviders<AvesdoCommonModule> {
    return {
      ngModule: AvesdoCommonModule,
      providers: [
        LanguageService,
        {
          provide: APP_INITIALIZER,
          useFactory: setupTranslateFactory,
          deps: [TranslateService],
          multi: true,
        },
        {
          provide: 'env',
          useValue: environment,
        },
        { provide: HTTP_INTERCEPTORS, useClass: NoCacheService, multi: true },
        { provide: avesdoCommonReducerToken, useFactory: getAvesdoCommonReducers }
      ],
    };
  }
}
